import { parsedUser } from "../../../common/GetCurrentUser"
import { PayrollTypes } from "../../../common/helpers"
import CRUDField from "../../core/genericCRUD/CRUDField"

const user = parsedUser()
console.log(user)
const AddEditEmployeeDTO: CRUDField[] = [
  {
    name: "id",

    htmlType: "hidden",
    dbType: "text",
    required: true,
  },
  {
    name: "organizationId",

    htmlType: "hidden",
    dbType: "text",
    required: true,
    value: user?.OrganizationId,
  },
  {
    name: "identifierType",
    title: "Identification Type",
    htmlType: "select",
    dbType: "text",
    containerClass: "col-md-6 col-12",
    dataSourceValueField: "id",
    dataSourceTextField: "name",
    dataSourceLocal: [
      { id: 1, name: "Cédula" },
      { id: 2, name: "Pasaporte" },
    ],
    condition: "organizationCountry='DO'",
  },
  {
    name: "identifierType",
    title: "Identification Type",
    htmlType: "select",
    dbType: "text",
    containerClass: "col-md-6 col-12",
    dataSourceValueField: "id",
    dataSourceTextField: "name",
    dataSourceLocal: [
      { id: 1, name: "Cédula" },
      { id: 2, name: "Pasaporte" },
    ],
    condition: "organizationCountry='USA'",
  },
  {
    name: "identifier",
    title: "Identification No.",
    htmlType: "cedula",
    dbType: "text",
    containerClass: "col-md-6 col-12",

    condition: "organizationCountry='DO'",
  },
  {
    name: "identifier",
    title: "Identification No.",
    htmlType: "text",
    dbType: "text",
    containerClass: "col-md-6 col-12",

    condition: "organizationCountry='USA'",
  },
  {
    name: "firstName",
    title: "First Name",
    htmlType: "text",
    dbType: "text",
    required: true,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "middleName",
    title: "Middle Name",
    htmlType: "text",
    dbType: "text",
    containerClass: "col-md-6 col-12",
  },
  {
    name: "lastName",
    title: "Last Name",
    htmlType: "text",
    dbType: "text",
    required: true,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "secondLastName",
    title: "Second Last Name",
    htmlType: "text",
    dbType: "text",
    containerClass: "col-md-6 col-12",
  },
  {
    name: "gender",
    title: "Gender",
    htmlType: "select",
    dbType: "text",
    required: true,
    containerClass: "col-md-6 col-12",
    dataSourceValueField: "id",
    dataSourceTextField: "name",
    dataSourceLocal: [
      { id: 1, name: "Male" },
      { id: 2, name: "Female" },
    ],
  },
  {
    name: "birthDate",
    title: "Birth Date",
    htmlType: "date",
    dbType: "date",

    containerClass: "col-md-6 col-12",
  },
  {
    name: "status",
    title: "Estatus",
    htmlType: "select",
    dbType: "int",
    required: true,
    containerClass: "col-md-6 col-12",
    dataSourceValueField: "id",
    dataSourceTextField: "name",
    dataSourceLocal: [
      { id: 1, name: "Active" },
      { id: 2, name: "Inactive" },
    ],
  },
  {
    name: "email",
    title: "E-Mail",
    htmlType: "email",
    dbType: "text",
    containerClass: " col-12",
  },
  {
    name: "phone",
    title: "Phone",
    htmlType: "text",
    dbType: "text",
    containerClass: "col-md-6 col-12",
  },
  {
    name: "mobilePhone",
    title: "Mobile Phone",
    htmlType: "text",
    dbType: "text",
    containerClass: "col-md-6 col-12",
  },
  {
    name: "separator",
    title: "Work information",
    htmlType: "separator",
    containerClass: "col-12",
    dbType: "text",
  },
  {
    name: "employeeType",
    title: "Employee Type",
    htmlType: "select",
    dbType: "int",
    required: true,
    containerClass: "col-md-6 col-12",
    dataSourceValueField: "id",
    dataSourceTextField: "name",
    dataSourceLocal: [
      { id: 0, name: "Regular" },
      { id: 1, name: "Temporal" },
    ],
  },

  {
    name: "payrollCategoryId",
    title: "Payroll Category",
    htmlType: "select",
    dbType: "int",
    required: false,
    containerClass: "col-md-6 col-12",
    dataSourceValueField: "id",
    dataSourceTextField: "name",
    datasourceMethod: "GET",
    dataSource:
      "employees/PayrollCategories?OrganizationId=" + user?.OrganizationId,
  },

  {
    name: "beginWorkingDate",
    title: "Begin Working Date",
    htmlType: "date",
    dbType: "date",
    required: true,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "employeePositionId",
    title: "Position",
    htmlType: "select",
    required: true,
    dbType: "string",
    containerClass: "col-md-6 col-12",
    dataSourceValueField: "id",
    dataSourceTextField: "name",
    datasourceMethod: "GET",
    dataSource: "employees/PositionList?OrganizationId=" + user?.OrganizationId,
  },
  {
    name: "basePayroll",
    title: "Base Payroll",
    htmlType: "number",
    dbType: "double",
    required: true,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "payrollTypeId",
    title: "Payroll type",
    htmlType: "select",
    dbType: "int",

    containerClass: "col-md-6 col-12",
    dataSourceValueField: "id",
    dataSourceTextField: "name",
    dataSourceLocal: [
      { id: PayrollTypes.hourly, name: "Hourly" },
      { id: PayrollTypes.weekly, name: "Weekly" },
      { id: PayrollTypes.biWeekly, name: "Bi-weekly" },
      { id: PayrollTypes.monthly, name: "Monthly" },
    ],
  },
  {
    name: "bank",
    title: "Bank",
    htmlType: "select",
    dbType: "string",
    containerClass: "col-md-6 col-12",
    dataSourceValueField: "id",
    dataSourceTextField: "name",
    datasourceMethod: "GET",
    dataSource: "banks/list?OrganizationId=" + user?.OrganizationId,
  },
  {
    name: "bankAccount",
    title: "Bank Account",
    htmlType: "text",
    dbType: "text",
    containerClass: "col-md-6 col-12",
  },
]

export default AddEditEmployeeDTO
