import React from "react"
import { FormatMoney } from "../../common/helpers"
import Pagination from "../core/Pagination"
import { useTranslation } from "react-i18next"
import { Card } from "react-bootstrap"

const ProductListView = props => {
  const { t } = useTranslation("global")
  const records = props.records
  return (
    <>
      <div className="card shadow-sm">
        <div className="card-body p-0 ">
          {records.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {/* <th>{t("container")}</th> */}
                    <th>{t("product")}</th>
                    <th className="text-center">{t("note")}</th>
                    <th>{t("quantity")}</th>
                    <th>{t("listPrice")}</th>
                    <th>{t("provider")}</th>
                    <th>{t("location")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((item, index) => {
                    return (
                      <tr key={index}>
                        {/* <td>{item.product.container?.name}</td> */}
                        <td
                          className=" clickable"
                          onClick={() => props.toggleAddEdit(item)}
                        >
                          {item.product.brand} {item.product.name} (ID{" "}
                          {item.product.barcode})
                        </td>
                        <td className="text-center">
                          {item.product.description && (
                            <i
                              title={item.product.description}
                              className="fas fa-align-justify"
                            ></i>
                          )}
                        </td>
                        <td>
                          {item.quantity}
                          {item.minimumQuantity ? (
                            <small>/ min. {item.minimumQuantity}</small>
                          ) : null}
                        </td>
                        <td>
                          {item.listPrice ? (
                            <>
                              <span>{FormatMoney(item.listPrice)}</span>
                            </>
                          ) : null}

                          {item.cost ? (
                            <small className="text-muted">
                              / {t("cost")}&nbsp;
                              {FormatMoney(item.cost)}
                            </small>
                          ) : null}
                        </td>
                        <td>{decodeURI(item.provider?.name)}</td>
                        <td>
                          {item.warehouseSection && (
                            <span>{`${item.warehouseSection.warehouse.name} - ${item.warehouseSection.area}`}</span>
                          )}
                        </td>
                        <td className="text-end">
                          <div className="btn-group">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => props.toggleAddEdit(item, false)}
                            >
                              <i className="fa fa-edit" />
                            </button>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => props.deleteConfirm(item)}
                              type="button"
                            >
                              <i className="fa fa-trash text-danger" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
      <Card className="my-4">
        <Card.Footer className="p-1">
          <Pagination
            totalPages={props.TotalPages}
            totalRecords={props.TotalRecords}
            currentPage={props.currentPage}
            onPageChanged={props.onPageChanged}
          />
        </Card.Footer>
      </Card>
    </>
  )
}

export default ProductListView

// return (
// 	<div className="row px-0 " key={index}>
// 	  <div
// 		className="col clickable"
// 		onClick={() => props.toggleAddEdit(item)}
// 	  >
// 		<span className="d-inline-block">
// 		  <label>
// 			{item.product.name} (ID {item.product.barcode})
// 		  </label>
// 		  {item.provider ? (
// 			<>
// 			  <br />
// 			  <small className="text-muted">
// 				{decodeURI(item.provider.name)}
// 			  </small>
// 			</>
// 		  ) : null}
// 		</span>
// 	  </div>
// 	  <div className="col">
// 		Cant. {item.quantity}
// 		{item.minimumQuantity ? (
// 		  <small>
// 			<br />/ min. {item.minimumQuantity}
// 		  </small>
// 		) : null}
// 	  </div>
// 	  <div className="col">
// 		{item.listPrice ? (
// 		  <>
// 			<span>
// 			  {t("price")} {FormatMoney(item.listPrice)}
// 			</span>
// 		  </>
// 		) : null}

// 		{item.cost ? (
// 		  <small className="text-muted">
// 			<br />/ {t("cost")}&nbsp;
// 			{FormatMoney(item.cost)}
// 		  </small>
// 		) : null}
// 	  </div>

// 	  <div className="col-auto text-right">
// 		<div className="btn-group">
// 		  <button
// 			className="btn btn-primary btn-sm"
// 			onClick={() => props.toggleAddEdit(item, false)}
// 		  >
// 			<i className="fa fa-edit" />
// 		  </button>
// 		  <button
// 			className="btn btn-primary btn-sm"
// 			onClick={() => props.deleteConfirm(item)}
// 			type="button"
// 		  >
// 			<i className="fa fa-trash text-danger" />
// 		  </button>
// 		</div>
// 	  </div>
// 	  <div className="col-12">
// 		<hr className="my-1" />
// 	  </div>
// 	</div>
//   )
// })
